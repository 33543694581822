// reference media queries at the bottom of this file
.device-notification
  display: none
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  flex-direction: column
  align-items: center
  justify-content: center
  background-color: $black
  z-index: 12

  &--logo
    display: flex
    align-items: center
    text-decoration: none
    color: $white
    padding-bottom: 5px

    p
      margin: 0 0 0 10px
      font-size: 16px
      font-weight: 700

  &--message
    width: 70%
    margin: 0
    font-weight: 700
    text-align: center

  // based on personal content these may need to be adjusted slighlty
  @media (max-width: 767px) and (min-width: 601px) and (max-height: 680px)
    display: flex

  @media (max-width: 600px) and (min-width: 480px) and (max-height: 580px)
    display: flex

  @media(max-width: 359px)
    display: flex
