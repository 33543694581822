@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Black.4ac4feed.eot");
  src: local(☺), url("Montserrat-Black.b1bb7349.woff") format("woff"), url("Montserrat-Black.4a30e5be.ttf") format("truetype"), url("Montserrat-Black.702f292b.svg") format("svg");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Bold.d291d3d8.eot");
  src: local(☺), url("Montserrat-Bold.7959586a.woff") format("woff"), url("Montserrat-Bold.a1f89e66.ttf") format("truetype"), url("Montserrat-Bold.f8db2c22.svg") format("svg");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Regular.22c46f38.eot");
  src: local(☺), url("Montserrat-Regular.77729a93.woff") format("woff"), url("Montserrat-Regular.e1b9b953.ttf") format("truetype"), url("Montserrat-Regular.3285bf9e.svg") format("svg");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Montserrat;
  src: url("Montserrat-Light.85c16784.eot");
  src: local(☺), url("Montserrat-Light.7bcde48b.woff") format("woff"), url("Montserrat-Light.399c37c6.ttf") format("truetype"), url("Montserrat-Light.839e2b3b.svg") format("svg");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI-Light.596cfc9c.eot");
  src: local(Segoe UI Light), local(SegoeUI-Light), url("SegoeUI-Light.596cfc9c.eot#iefix") format("embedded-opentype"), url("SegoeUI-Light.a7e82bf8.woff") format("woff"), url("SegoeUI-Light.b5933603.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI-BoldItalic.c44cba56.eot");
  src: local(Segoe UI Bold Italic), local(SegoeUI-BoldItalic), url("SegoeUI-BoldItalic.c44cba56.eot#iefix") format("embedded-opentype"), url("SegoeUI-BoldItalic.0f8795a2.woff") format("woff"), url("SegoeUI-BoldItalic.85566609.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI-SemiBold.07282634.eot");
  src: local(Segoe UI Semibold), local(SegoeUI-SemiBold), url("SegoeUI-SemiBold.07282634.eot#iefix") format("embedded-opentype"), url("SegoeUI-SemiBold.5786e51b.woff") format("woff"), url("SegoeUI-SemiBold.d09fa3b0.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI.82349db3.eot");
  src: local(Segoe UI), local(SegoeUI), url("SegoeUI.82349db3.eot#iefix") format("embedded-opentype"), url("SegoeUI.aee77b7d.woff") format("woff"), url("SegoeUI.4ec0a8a9.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI-Bold.e1431019.eot");
  src: local(Segoe UI Bold), local(SegoeUI-Bold), url("SegoeUI-Bold.e1431019.eot#iefix") format("embedded-opentype"), url("SegoeUI-Bold.612d528a.woff") format("woff"), url("SegoeUI-Bold.2d0c5068.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: Segoe UI;
  src: url("SegoeUI-Italic.5f14cadc.eot");
  src: local(Segoe UI Italic), local(SegoeUI-Italic), url("SegoeUI-Italic.5f14cadc.eot#iefix") format("embedded-opentype"), url("SegoeUI-Italic.4f1d122e.woff") format("woff"), url("SegoeUI-Italic.ee648130.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-family: sans-serif;
}

body {
  margin: 0;
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block;
}

audio, canvas, progress, video {
  vertical-align: baseline;
  display: inline-block;
}

audio:not([controls]) {
  height: 0;
  display: none;
}

[hidden], template {
  display: none;
}

a {
  background-color: #0000;
}

a:active, a:hover {
  outline: 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b, strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

mark {
  color: #000;
  background: #ff0;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sup {
  top: -.5em;
}

sub {
  bottom: -.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 1em 40px;
}

hr {
  box-sizing: content-box;
  height: 0;
}

pre {
  overflow: auto;
}

code, kbd, pre, samp {
  font-family: monospace;
  font-size: 1em;
}

button, input, optgroup, select, textarea {
  color: inherit;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, html input[type="button"], input[type="reset"], input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled], html input[disabled] {
  cursor: default;
}

button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

input {
  line-height: normal;
}

input[type="checkbox"], input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="number"]::-webkit-inner-spin-button {
  height: auto;
}

input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}

input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
}

input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

input:-webkit-autofill {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill:hover {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: #fff !important;
}

input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out;
  -webkit-text-fill-color: #fff !important;
}

fieldset {
  border: 1px solid silver;
  margin: 0 2px;
  padding: .35em .625em .75em;
}

legend {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
}

optgroup {
  font-weight: bold;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

td, th {
  padding: 0;
}

::selection {
  background: #8b6600;
}

::selection {
  background: #8b6600;
}

img::selection {
  background: none;
}

img::selection {
  background: none;
}

body {
  -webkit-tap-highlight-color: #8b6600;
  color: #fff;
  -webkit-font-smoothing: antialiased;
  -webkit-text-size-adjust: 100%;
  background-color: #0c0c0c;
  font-family: Montserrat, sans-serif;
  font-size: 14px;
  line-height: 1.6;
}

.l-viewport {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow: hidden;
  box-shadow: 0 0 45px 5px #000000d9;
}

.l-wrapper {
  width: 1440px;
  max-width: 90%;
  height: 100%;
  margin: 0 auto;
  position: relative;
}

.l-side-nav {
  align-items: center;
  height: 100%;
  display: flex;
  position: absolute;
  left: 0;
}

.l-side-nav:before {
  content: "";
  opacity: .35;
  z-index: 10;
  background-color: #555;
  width: 2px;
  height: 70%;
  max-height: 750px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

@media (width <= 1180px) {
  .l-side-nav {
    display: none;
  }
}

.l-main-content {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  position: relative;
}

.l-section {
  width: 100%;
  height: 100%;
  position: absolute;
}

.jc_st {
  justify-content: flex-start !important;
}

.device-notification {
  z-index: 12;
  background-color: #0c0c0c;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  display: none;
  position: fixed;
  top: 0;
  left: 0;
}

.device-notification--logo {
  color: #fff;
  align-items: center;
  padding-bottom: 5px;
  text-decoration: none;
  display: flex;
}

.device-notification--logo p {
  margin: 0 0 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.device-notification--message {
  text-align: center;
  width: 70%;
  margin: 0;
  font-weight: 700;
}

@media (width <= 767px) and (width >= 601px) and (height <= 680px) {
  .device-notification {
    display: flex;
  }
}

@media (width <= 600px) and (width >= 480px) and (height <= 580px) {
  .device-notification {
    display: flex;
  }
}

@media (width <= 359px) {
  .device-notification {
    display: flex;
  }
}

.section {
  opacity: 0;
  visibility: hidden;
  transition: opacity .4s ease-in-out, visibility 0s .4s;
}

.section--is-active {
  opacity: 1;
  visibility: visible;
  z-index: 1;
  transition: opacity .4s ease-in-out .4s;
}

.section--next {
  transition: transform .4s ease-in-out;
  transform: translateY(-45px);
}

.section--prev {
  transition: transform .4s ease-in-out;
  transform: translateY(45px);
}

.header {
  z-index: 10;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70px;
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
}

.header--logo {
  color: #fff;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.header--logo p {
  margin: 0 0 0 10px;
  font-size: 16px;
  font-weight: 700;
}

.header--nav-toggle {
  cursor: pointer;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  display: flex;
}

.header--nav-toggle span, .header--nav-toggle:before, .header--nav-toggle:after {
  content: "";
  background-color: #fff;
  width: 16px;
  height: 2px;
  position: relative;
}

.header--nav-toggle:before {
  width: 23px;
  bottom: 5px;
}

.header--nav-toggle:after {
  width: 23px;
  top: 5px;
}

.header--cta {
  color: #fff;
  text-transform: uppercase;
  opacity: 0;
  visibility: hidden;
  background-color: #0f33ff;
  border: none;
  padding: 0 20px;
  font-weight: 700;
  line-height: 30px;
  text-decoration: none;
  transition: opacity .4s ease-in-out, visibility 0s .4s;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header--cta:focus {
  outline: none;
}

.header--cta.is-active {
  opacity: 1;
  visibility: visible;
  transition: opacity .4s ease-in-out .4s;
}

@media (width <= 767px) {
  .header--cta {
    display: none;
  }
}

.side-nav {
  z-index: 10;
  -ms-touch-action: none;
  touch-action: none;
  flex-direction: column;
  justify-content: space-around;
  width: 100px;
  height: 70%;
  max-height: 750px;
  margin: 0;
  padding: 0;
  list-style-position: inside;
  display: flex;
  position: relative;
}

.side-nav > li {
  color: #fff;
  cursor: pointer;
  font-size: 6px;
  position: relative;
  top: -5px;
}

.side-nav > li span {
  color: #fff;
  opacity: 0;
  visibility: hidden;
  font-size: 14px;
  font-weight: 300;
  position: relative;
  top: 3px;
  left: 10px;
}

.side-nav > li:before {
  color: #555;
  font-size: 14px;
  font-weight: 300;
  position: absolute;
  top: 3px;
  left: 10px;
}

.side-nav li:first-child:before {
  content: "01";
}

.side-nav li:nth-child(2):before {
  content: "02";
}

.side-nav li:nth-child(3):before {
  content: "03";
}

.side-nav li:nth-child(4):before {
  content: "04";
}

.side-nav li:nth-child(5):before {
  content: "05";
}

.side-nav li.is-active {
  color: #d92337;
  transition: color .4s ease-in-out;
}

.side-nav li.is-active span {
  opacity: 1;
  visibility: visible;
  transition: opacity .4s ease-in-out;
}

.side-nav li.is-active:before {
  color: #fff;
  left: -33px;
}

.cta {
  z-index: 10;
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border-radius: 4px;
  width: 180px;
  height: 50px;
  transition: opacity .6s;
}

.cta:hover {
  opacity: .5;
}

#q-canvas {
  position: absolute;
  top: -30vh;
  left: 0;
}

@media screen and (width >= 1500px) {
  #q-canvas {
    left: -16vw;
  }
}

@media screen and (width <= 1200px) {
  #q-canvas {
    left: 13vw;
  }
}

@media screen and (height >= 1500px) {
  #q-canvas {
    top: -40vh;
  }
}

#overlay-canvas {
  cursor: pointer;
  z-index: 1;
  width: 400px;
  height: 400px;
  position: absolute;
  left: 35vw;
}

@media screen and (width >= 1500px) {
  #overlay-canvas {
    left: 25vw;
  }
}

@media screen and (width <= 1200px) {
  #overlay-canvas {
    left: 40vw;
  }
}

#quanti-ball {
  z-index: 10;
  cursor: pointer;
  flex: 2;
  width: 500px;
  max-width: 700px;
  height: 400px;
  max-height: 350px;
}

canvas {
  position: absolute;
  top: -26%;
  left: -4%;
}

.intro {
  flex-direction: column;
  justify-content: center;
  width: 900px;
  max-width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (width <= 1180px) {
  .intro {
    max-width: 100%;
  }
}

.intro--banner {
  height: 475px;
  position: relative;
}

.intro--banner:before {
  content: "";
  background-color: #282828;
  height: 2px;
  position: absolute;
  bottom: 20px;
  left: -15px;
  right: 0;
}

.intro--banner:after {
  content: "";
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  width: 30px;
  height: 4px;
  position: absolute;
  bottom: 18px;
  left: 0;
}

.intro--banner h1 {
  z-index: 1;
  font-size: 68px;
  font-weight: 900;
  line-height: 1;
  position: relative;
}

.intro--banner button {
  text-transform: uppercase;
  background-color: #0000;
  border: none;
  padding: 5px 17px 5px 12px;
  font-weight: 700;
  position: relative;
}

.intro--banner button .btn-background {
  z-index: -1;
  background-color: #0f33ff;
  height: 100%;
  transition: left .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 23px;
  right: 0;
}

.intro--banner button:hover .btn-background {
  left: 0;
}

.intro--banner button:focus {
  outline: none;
}

.intro--banner button svg {
  fill: #fff;
  width: 15px;
  position: relative;
  left: 5px;
}

.intro--banner img {
  position: absolute;
  bottom: 21px;
  right: -12px;
}

.intro--options {
  z-index: 10;
  justify-content: space-between;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.intro--options > a {
  color: #282828;
  cursor: default;
  max-width: 250px;
  text-decoration: none;
  transition: color .2s ease-in-out;
}

.intro--options > a:hover {
  color: #fff;
}

.intro--options h3 {
  text-transform: uppercase;
  font-size: 16px;
}

.intro--options p {
  margin-bottom: 0;
}

@media (width <= 900px) {
  .intro--banner {
    height: 380px;
  }

  .intro--banner h1 {
    font-size: 55px;
  }

  .intro--banner img {
    width: 430px;
  }

  .intro--banner #quanti-ball, .intro--banner canvas {
    display: none;
  }

  .intro--options > a {
    margin-right: 30px;
  }

  .intro--options > a:last-child {
    margin-right: 0;
  }
}

@media (width <= 767px) {
  .intro--banner {
    height: 305px;
  }

  .intro--banner h1 {
    font-size: 44px;
  }

  .intro--banner img {
    width: 330px;
  }

  .intro--options {
    display: block;
  }

  .intro--options > a {
    max-width: 100%;
    margin: 0 0 30px;
    display: block;
  }

  .intro--options > a:last-child {
    margin-bottom: 0;
  }
}

@media (width <= 600px) {
  .intro--banner {
    height: 360px;
  }

  .intro--banner h1 {
    font-size: 55px;
  }

  .intro--banner img {
    display: none;
  }
}

@media (width <= 600px) and (height <= 750px) {
  .intro--banner {
    height: auto;
  }

  .intro--banner:before, .intro--banner:after {
    display: none;
  }

  .intro--banner h1 {
    margin-top: 0;
  }

  .intro--options {
    display: none;
  }
}

.work {
  flex-direction: column;
  justify-content: center;
  width: 960px;
  max-width: 80%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (width <= 1180px) {
  .work {
    max-width: 100%;
  }
}

.work h2 {
  text-align: center;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin: 0 0 20px;
  font-family: Segoe UI;
  font-size: 30px;
  font-weight: 600;
}

.work--lockup {
  height: 400px;
  display: flex;
  position: relative;
}

.work--lockup .slider {
  width: 80%;
  margin: 0 auto;
  padding: 0;
  list-style: none;
  transition: opacity .3s ease-in-out;
  display: flex;
  position: relative;
}

.work--lockup .slider--item {
  text-align: center;
  display: none;
  position: absolute;
}

.work--lockup .slider--item a {
  color: #858585;
  cursor: default;
  text-decoration: none;
}

.work--lockup .slider--item-title {
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
  margin-top: 10px;
  font-size: 12px;
  font-weight: 700;
}

.work--lockup .slider--item-description {
  text-align: center;
  max-width: 400px;
  margin: 0 auto;
  font-weight: 500;
  display: none;
}

.work--lockup .slider--item-image {
  cursor: pointer;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: 0 auto;
  transition: opacity .5s;
  overflow: hidden;
}

.work--lockup .slider--item-image:hover {
  opacity: .5;
}

.work--lockup .slider--item-image:hover ~ .slider--item-title {
  text-decoration: underline;
}

.work--lockup .slider--item-image img {
  width: 100%;
}

.work--lockup .slider--item-left {
  pointer-events: none;
  cursor: default;
  opacity: .5;
  display: block;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.work--lockup .slider--item-right {
  pointer-events: none;
  cursor: default;
  opacity: .5;
  display: block;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
}

.work--lockup .slider--item-center {
  display: block;
  position: relative;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.work--lockup .slider--item-center img {
  transition: opacity .6s;
}

.work--lockup .slider--item-center img:hover {
  opacity: .5;
}

.work--lockup .slider--item-center a {
  color: #fff;
}

.work--lockup .slider--item-center .slider--item-title {
  color: #da4735;
  cursor: pointer;
  margin-top: 25px;
  font-size: 18px;
  display: inline-block;
}

@media (width <= 600px) {
  .work--lockup .slider--item-center .slider--item-title {
    text-decoration: underline;
  }
}

.work--lockup .slider--item-center .slider--item-title:hover {
  text-decoration: underline;
}

.work--lockup .slider--item-center .slider--item-description {
  display: block;
}

.work--lockup .slider--item-center .slider--item-image {
  width: 300px;
  height: 300px;
}

.work--lockup .slider--next, .work--lockup .slider--prev {
  cursor: pointer;
  background-color: #282828;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  transition: transform .4s;
  display: flex;
  position: absolute;
  top: 160px;
}

.work--lockup .slider--next:hover, .work--lockup .slider--prev:hover {
  transform: scale(1.2);
}

.work--lockup .slider--next svg, .work--lockup .slider--prev svg {
  fill: #fff;
  width: 20px;
}

.work--lockup .slider--next {
  right: 0;
}

.work--lockup .slider--prev {
  left: 0;
}

.hover {
  opacity: .3;
  transition: opacity .5s;
}

@media (width <= 1024px) {
  .work--lockup .slider--item-image, .work--lockup .slider--item-title {
    pointer-events: none;
  }
}

@media (width <= 900px) {
  .work--lockup .slider--item-image {
    width: 120px;
    height: 120px;
  }

  .work--lockup .slider--item-center .slider--item-image {
    width: 240px;
    height: 240px;
  }

  .work--lockup .slider--next, .work--lockup .slider--prev {
    top: 130px;
  }
}

@media (width <= 768px) {
  .work--lockup .slider {
    width: 75%;
  }

  .work--lockup .slider--item-left, .work--lockup .slider--item-right {
    top: 37%;
  }

  .work--lockup .slider--item-image {
    width: 90px;
    height: 90px;
  }

  .work--lockup .slider--item-center .slider--item-image {
    width: 190px;
    height: 190px;
  }

  .work--lockup .slider--next, .work--lockup .slider--prev {
    top: 105px;
  }
}

@media (width <= 600px) {
  .work--lockup .slider {
    width: auto;
  }

  .work--lockup .slider--item-left, .work--lockup .slider--item-right {
    display: none;
  }
}

.about {
  flex-direction: column;
  justify-content: center;
  width: 900px;
  max-width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (width <= 1180px) {
  .about {
    max-width: 100%;
  }
}

.about--banner {
  height: 475px;
  position: relative;
}

.about--banner:before {
  content: "";
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 20px;
  left: 200px;
  transform: rotate(-75deg);
}

.about--banner:after {
  content: "";
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 75px;
  left: 400px;
  transform: rotate(45deg);
}

.about--banner h2 {
  z-index: 1;
  margin-top: 35px;
  font-size: 68px;
  font-weight: 900;
  line-height: 1;
  position: relative;
}

.about--banner h2:before {
  content: "";
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border-radius: 50%;
  width: 30px;
  height: 30px;
  position: absolute;
  top: 60px;
  left: 268px;
}

.about--banner h2:after {
  content: "";
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  width: 10px;
  height: 10px;
  position: absolute;
  top: 255px;
  left: 255px;
}

.about--banner a {
  color: #fff;
  text-transform: uppercase;
  background-color: #0000;
  padding: 5px 17px 5px 0;
  font-weight: 700;
  text-decoration: none;
}

.about--banner a:hover svg {
  left: 10px;
}

.about--banner a svg {
  fill: #fff;
  width: 15px;
  transition: left .2s ease-in-out;
  position: relative;
  left: 5px;
}

.about--banner img {
  position: absolute;
  bottom: -90px;
  right: -12px;
}

.about--options--mobile {
  display: none;
}

.about--options {
  justify-content: space-between;
  max-width: 600px;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

@media screen and (width <= 600px) {
  .about--options {
    flex-direction: column;
    align-items: center;
    gap: 25px;
  }
}

.about--options > a {
  color: #fff;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: padding-box;
  border: 10px solid #0000;
  border-radius: 4px;
  min-width: 29%;
  min-height: 100px;
  text-decoration: none;
  position: relative;
}

.about--options > a:before {
  content: "";
  z-index: -1;
  border-radius: inherit;
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  margin: -10px;
  position: absolute;
  inset: 0;
}

.about--options > a:first-child {
  background-image: url("about-winners.55ccaa66.jpg");
}

.about--options > a:nth-child(2) {
  background-image: url("about-philosophy.ff6cc02d.jpg");
}

.about--options > a:nth-child(3) {
  background-image: url("about-history.6970954f.jpg");
}

.about--options > a:hover h3 {
  bottom: -50px;
}

.about--options h3 {
  text-transform: uppercase;
  letter-spacing: 3px;
  margin: 0;
  font-family: Segoe UI;
  font-size: 15px;
  font-weight: 600;
  transition: bottom .2s ease-in-out, left .2s ease-in-out;
  position: absolute;
  bottom: -30px;
  left: 10px;
}

@media (width <= 767px) {
  .about--banner {
    height: 305px;
  }

  .about--banner:before {
    top: 0;
    left: 125px;
  }

  .about--banner:after {
    top: 35px;
    left: 260px;
  }

  .about--banner h2 {
    margin-top: 10px;
    font-size: 44px;
  }

  .about--banner h2:before {
    top: 28px;
    left: 168px;
  }

  .about--banner h2:after {
    top: 163px;
    left: 163px;
  }

  .about--banner img {
    width: 315px;
  }
}

@media (width <= 600px) {
  .about--banner {
    height: auto;
  }

  .about--banner:before {
    left: 155px;
  }

  .about--banner:after {
    left: 310px;
  }

  .about--banner h2 {
    margin-top: 0;
    font-size: 55px;
  }

  .about--banner h2:before {
    top: 43px;
    left: 214px;
  }

  .about--banner h2:after {
    top: 205px;
    left: 205px;
  }

  .about img, .about--options {
    display: none;
  }

  .about--options--mobile {
    text-transform: uppercase;
    text-align: center;
    flex-direction: column;
    gap: 10px;
    width: 60vw;
    margin: 100px auto 0;
    display: flex;
  }

  .about--options--mobile--row {
    justify-content: space-between;
    display: flex;
  }

  .about--options--mobile a {
    color: #fff;
    align-self: center;
    margin-bottom: 20px;
    padding-right: 5px;
    text-decoration: none;
    display: inline-block;
    position: relative;
  }

  .about--options--mobile a:hover .btn-background {
    left: -5px;
  }

  .about--options--mobile a h3 {
    margin: 0;
    font-family: Segoe UI;
  }

  .about--options--mobile a .btn-background {
    z-index: -1;
    background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
    border-radius: 5px;
    height: 100%;
    transition: left .2s ease-in-out;
    position: absolute;
    top: 0;
    left: 25px;
    right: 0;
  }
}

.webp .contact {
  background-image: url("contact-visual.561c81e6.webp");
}

.no-webp .contact {
  background-image: url("contact-visual.36a84486.png");
}

.contact {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
}

@media screen and (width >= 1500px) {
  .contact:after {
    content: " ";
    z-index: 0;
    background: linear-gradient(90deg, #000c calc(40% - 720px), #0000 calc(60% - 720px) calc(45% + 720px), #000c calc(55% + 720px));
    border-radius: 15px;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}

.contact--lockup {
  justify-content: flex-end;
  align-items: center;
  width: 900px;
  max-width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (width <= 1180px) {
  .contact--lockup {
    max-width: 90%;
  }
}

@media (width <= 767px) {
  .contact--lockup {
    justify-content: center;
  }
}

.contact--lockup .card {
  text-align: center;
  z-index: 10000;
  background-color: #0c0c0c;
  border-radius: 4px;
  padding: 45px 42px;
  box-shadow: 0 0 30px #000000bf;
}

.contact--lockup .card--information {
  margin-bottom: 30px;
}

.contact--lockup .card--information a, .contact--lockup .card--information p {
  color: #fff;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 0 0 7px;
  font-weight: 700;
  text-decoration: none;
  display: flex;
}

.contact--lockup .card--information p {
  margin-top: 0;
  margin-bottom: auto;
}

.contact--lockup .card--information p.call {
  margin: 14px 0;
}

.contact--lockup .card--options {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact--lockup .card--options > li {
  border-radius: 4px;
  width: 130px;
  margin: 0 auto 25px;
}

.contact--lockup .card--options li:first-child {
  background-image: linear-gradient(-115deg, #007bb6 0%, #05359d 95%);
  transition: opacity .6s;
}

.contact--lockup .card--options li:first-child:hover {
  opacity: .5;
}

.contact--lockup .card--options li:nth-child(2) {
  background-image: linear-gradient(-115deg, #00e6ff 0%, #08a0e9 50%, #155bf3 95%);
  transition: opacity .6s;
}

.contact--lockup .card--options li:nth-child(2):hover {
  opacity: .5;
}

.contact--lockup .card--options li:nth-child(3) {
  text-transform: uppercase;
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  margin-bottom: 0;
  transition: opacity .6s;
}

.contact--lockup .card--options li:nth-child(3):hover {
  opacity: .5;
}

.contact--lockup .card--options a {
  color: #fff;
  width: 100%;
  padding: 8px 0;
  font-weight: 700;
  text-decoration: none;
  display: block;
}

.loader {
  border: 16px solid #fff;
  border-top-color: #db6533;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  animation: 2s linear infinite spin;
  display: none;
  position: fixed;
  top: 40%;
  left: 48%;
  transform: translate(-40%, -45%);
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.hire {
  flex-direction: column;
  justify-content: center;
  width: 700px;
  max-width: 75%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

@media (width <= 1180px) {
  .hire {
    max-width: 100%;
  }
}

.hire h2 {
  text-align: center;
  letter-spacing: 12px;
  text-transform: uppercase;
  margin: 0 0 20px;
  font-family: Segoe UI;
  font-size: 30px;
  font-weight: 600;
}

@media (height <= 560px) {
  .hire h2 {
    letter-spacing: 3px;
    margin: 0;
  }
}

.work-request {
  color: #fff;
  flex-direction: column;
  align-items: center;
  width: 100%;
  display: flex;
}

.work-request input[type="submit"] {
  text-transform: uppercase;
  background-color: #0c0c0c;
  border: 2px solid #fff;
  border-radius: 4px;
  width: 120px;
  max-width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  transition: border .6s ease-out;
  position: relative;
}

.work-request input[type="submit"]:hover {
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border: 2px solid #0000;
}

.work-request input[type="submit"]:focus {
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border: 2px solid #0000;
  outline: none;
}

.work-request--options {
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 30px 0;
  display: flex;
}

.work-request--options .options-a {
  justify-content: space-between;
  width: 100%;
  display: flex;
}

.work-request--options .options-b {
  flex-wrap: wrap;
  justify-content: space-around;
  width: 72%;
  display: flex;
}

.work-request--options label {
  text-align: center;
  cursor: pointer;
  border: 2px solid #fff;
  border-radius: 4px;
  width: 200px;
  margin-bottom: 30px;
  font-size: 16px;
  font-weight: 700;
  line-height: 50px;
  transition: color .5s, border .5s;
  display: block;
}

.work-request--options label svg {
  fill: #fff;
  width: 0;
  transition: width .2s ease-in-out;
  position: relative;
  left: -5px;
}

.work-request--options input[type="checkbox"] {
  display: none;
}

.work-request--options input[type="checkbox"]:checked + label {
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border: 2px solid #0000;
}

.work-request--options input[type="checkbox"]:checked + label:hover {
  color: #fff;
}

.work-request--options input[type="checkbox"]:checked + label svg {
  width: 15px;
}

.work-request--information {
  justify-content: space-between;
  width: 100%;
  margin-bottom: 60px;
  display: flex;
}

.work-request--information .information-name, .work-request--information .information-email {
  width: 45%;
  height: 50px;
  font-size: 30px;
  font-weight: 300;
  position: relative;
}

.work-request--information input[type="text"], .work-request--information input[type="email"] {
  background-color: #0000;
  border: none;
  border-bottom: 1px solid #fff;
  border-radius: 0;
  width: 100%;
  padding: 0 0 5px;
}

.work-request--information input[type="text"]:focus, .work-request--information input[type="email"]:focus {
  background-color: #0c0c0c;
  outline: none;
}

.work-request--information label {
  pointer-events: none;
  transition: top .2s ease-in-out, font-size .2s ease-in-out;
  position: absolute;
  top: 0;
  left: 0;
}

.work-request--information input:focus + label, .work-request--information input.has-value + label {
  font-size: 14px;
  top: -15px;
}

@media (width >= 1025px) {
  .work-request--options label:hover {
    color: #4b4b4b;
    border: 2px solid #4b4b4b;
  }
}

@media (width <= 767px) {
  .work-request--options {
    flex-direction: row;
    justify-content: space-around;
  }

  .work-request--options .options-a, .work-request--options .options-b {
    width: auto;
    display: block;
  }
}

@media (width <= 600px) {
  .work-request--options {
    margin: 20px 0;
  }
}

@media (width <= 600px) and (width <= 415px) {
  .work-request--options {
    justify-content: space-between;
  }
}

@media (width <= 600px) {
  .work-request--options label {
    width: 150px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  .work-request--options input[type="checkbox"]:checked + label svg {
    width: 12px;
  }

  .work-request--information {
    margin-bottom: 30px;
  }

  .work-request--information .information-name, .work-request--information .information-email {
    height: 40px;
    font-size: 24px;
  }
}

.perspective {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.perspective--modalview {
  perspective: 1500px;
  position: fixed;
}

.container {
  min-height: 100%;
  transition: transform .4s;
  position: relative;
  transform: translateZ(0)translateX(0)rotateY(0);
}

.modalview .container {
  backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: hidden;
}

.effect-rotate-left .container {
  transform-origin: 0%;
  transition: transform .4s;
}

.effect-rotate-left--animate .container {
  transform: translateZ(-1800px)translateX(-50%)rotateY(45deg);
}

.effect-rotate-left--animate .container:before {
  content: " ";
  z-index: -1;
  background: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  border-radius: 15px;
  width: 100%;
  height: 100%;
  padding: 20px;
  position: absolute;
  top: -20px;
  left: -20px;
}

.effect-rotate-left--animate .container > div {
  z-index: 1;
  background-color: #0c0c0c;
  border: 1px solid #0c0c0c;
  border-radius: 15px;
}

.outer-nav {
  transform-style: preserve-3d;
  text-align: center;
  visibility: hidden;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: visibility 0s .2s;
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translateY(-50%);
}

.outer-nav.is-vis {
  visibility: visible;
}

.outer-nav--return {
  cursor: pointer;
  z-index: 11;
  width: 100%;
  height: 100%;
  display: none;
  position: absolute;
  top: 0;
  left: 0;
}

.outer-nav--return.is-vis {
  display: block;
}

.outer-nav > li {
  transform-style: preserve-3d;
  opacity: 0;
  cursor: pointer;
  font-size: 55px;
  font-weight: 900;
  transition: transform .2s, opacity .2s;
  transform: translateX(350px)translateZ(-1000px);
}

.outer-nav > li.is-vis {
  opacity: 1;
  transition: transform .4s, opacity .4s;
  transform: translateX(0)translateZ(0);
}

.outer-nav > li:before {
  content: "";
  opacity: 0;
  background-image: linear-gradient(210deg, #db6533 0%, #d92337 90%);
  width: 110%;
  height: 15px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -25%);
}

.outer-nav > li.is-active:before {
  opacity: 1;
  border-radius: 6px;
}

@media (width <= 767px) {
  .outer-nav > li {
    font-size: 44px;
  }
}

@media (width <= 600px) {
  .outer-nav > li {
    font-size: 34px;
  }
}

.outer-nav li.is-vis:nth-child(2) {
  transition-delay: 40ms;
}

.outer-nav li.is-vis:nth-child(3) {
  transition-delay: 80ms;
}

.outer-nav li.is-vis:nth-child(4) {
  transition-delay: .12s;
}

.outer-nav li.is-vis:nth-child(5) {
  transition-delay: .16s;
}

div.modal {
  background-color: #070707;
}

.modal--title {
  background: linear-gradient(80deg, #db6533 0%, #d92337 90%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.modalDialog {
  z-index: 0;
  opacity: 0;
  background: #000c;
  transition: opacity .1s ease-in;
  position: fixed;
  inset: 0;
}

.modalDialog > div {
  background: #070707;
  border-radius: 8px;
  width: 40%;
  max-width: 800px;
  margin: 30% auto;
  padding: 20px;
  position: relative;
}

.modalDialog > div p {
  margin-bottom: 0;
}

.modalDialog > div h2 {
  background: linear-gradient(80deg, #db6533 0%, #d92337 90%);
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  margin: 0;
}

.close {
  text-align: center;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8CAYAAAA6/NlyAAAAAXNSR0IArs4c6QAAA3hJREFUaAXlm8+K00Acx7MiCIJH/yw+gA9g25O49SL4AO3Bp1jw5NvktC+wF88qevK4BU97EmzxUBCEolK/n5gp3W6TTJPfpNPNF37MNsl85/vN/DaTmU6PknC4K+pniqeKJ3k8UnkvDxXJzzy+q/yaxxeVHxW/FNHjgRSeKt4rFoplzaAuHHDBGR2eS9G54reirsmienDCTRt7xwsp+KAoEmt9nLaGitZxrBbPFNaGfPloGw2t4JVamSt8xYW6Dg1oCYo3Yv+rCGViV160oMkcd8SYKnYV1Nb1aEOjCe6L5ZOiLfF120EjWhuBu3YIZt1NQmujnk5F4MgOpURzLfAwOBSTmzp3fpDxuI/pabxpqOoz2r2HLAb0GMbZKlNV5/Hg9XJypguryA7lPF5KMdTZQzHjqxNPhWhzIuAruOl1eNqKEx1tSh5rfbxdw7mOxCq4qS68ZTjKS1YVvilu559vWvFHhh4rZrdyZ69Vmpgdj8fJbDZLJpNJ0uv1cnr/gjrUhQMuI+ANjyuwftQ0bbL6Erp0mM/ny8Fg4M3LtdRxgMtKl3jwmIHVxYXChFy94/Rmpa/pTbNUhstKV+4Rr8lLQ9KlUvJKLyG8yvQ2s9SBy1Jb7jV5a0yapfF6apaZLjLLcWtd4sNrmJUMHyM+1xibTjH82Zh01TNlhsrOhdKTe00uAzZQmN6+KW+sDa/JD2PSVQ873m29yf+1Q9VDzfEYlHi1G5LKBBWZbtEsHbFwb1oYDwr1ZiF/2bnCSg1OBE/pfr9/bWx26UxJL3ONPISOLKUvQza0LZUxSKyjpdTGa/vDEr25rddbMM0Q3O6Lx3rqFvU+x6UrRKQY7tyrZecmD9FODy8uLizTmilwNj0kraNcAJhOp5aGVwsAGD5VmJBrWWbJSgWT9zrzWepQF47RaGSiKfeGx6Szi3gzmX/HHbihwBser4B9UJYpFBNX4R6vTn3VQnez0SymnrHQMsRYGTr1dSk34ljRqS/EMd2pLQ8YBp3a1PLfcqCpo8gtHkZFHKkTX6fs3MY0blKnth66rKCnU0VRGu37ONrQaA4eZDFtWAu2fXj9zjFkxTBOo8F7t926gTp/83Kyzzcy2kZD6xiqxTYnHLRFm3vHiRSwNSjkz3hoIzo8lCKWUlg/YtGs7tObunDAZfpDLbfEI15zsEIY3U/x/gHHc/G1zltnAgAAAABJRU5ErkJggg==");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  padding-top: 5px;
  text-decoration: none;
  position: absolute;
  top: -10px;
  right: -5px;
  box-shadow: 1px 1px 3px #000;
}

.close:hover {
  background: #fa3f6f;
}

/*# sourceMappingURL=index.13989e15.css.map */
