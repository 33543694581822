// Colors
$white: #fff
$black: #0c0c0c
$gradient-from: #db6533
$gradient-to: #d92337
$highlight: #0f33ff
$muted-gray: #282828
$radius: 4px
=bg-gradient
  background-image: linear-gradient(210deg, $gradient-from 0%, $gradient-to 90%)
