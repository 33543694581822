.header
  position: absolute
  top: 0
  left: 0
  display: flex
  width: 100%
  height: 70px
  align-items: center
  justify-content: space-between
  z-index: 10

  &--logo
    display: flex
    align-items: center
    text-decoration: none
    color: $white

    p
      margin: 0 0 0 10px
      font-size: 16px
      font-weight: 700

  &--nav-toggle
    display: flex
    width: 50px
    height: 50px
    flex-direction: column
    align-items: center
    justify-content: center
    cursor: pointer

    span,
    &::before,
    &::after
      content: ""
      position: relative
      width: 16px
      height: 2px
      background-color: $white

    &::before
      bottom: 5px
      width: 23px

    &::after
      top: 5px
      width: 23px

  &--cta
    position: absolute
    top: 50%
    left: 50%
    transform: translate(-50%, -50%)
    padding: 0 20px
    line-height: 30px
    text-decoration: none
    color: $white
    font-weight: 700
    text-transform: uppercase
    background-color: $highlight
    border: none
    opacity: 0
    visibility: hidden
    transition: opacity .4s ease-in-out, visibility 0s .4s

    &:focus
      outline: none

    &.is-active
      opacity: 1
      visibility: visible
      transition: opacity .4s ease-in-out .4s

    @media (max-width: 767px)
      display: none
