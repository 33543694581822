// position fixed in order to escape the 1440px wrapper
.webp
  .contact
    background:
      image: url("../img/contact-visual.webp")

.no-webp
  .contact
    background:
      image: url("../img/contact-visual.png")

.contact
  position: fixed
  top: 0
  left: 0
  width: 100%
  height: 100%
  border-radius: 15px
  background:
    position: center
    size: cover
    repeat: no-repeat
  @media screen and (min-width: 1500px)
    &:after
      content: ' '
      position: absolute
      border-radius: 15px
      top: 0
      left: 0
      width: 100%
      height: 100%
      background: rgb(0,0,0)
      background: linear-gradient(90deg, rgba(0,0,0,0.8) calc((100% - 1440px)/2 - 10%), rgba(0,0,0,0) calc((100% - 1440px)/2 + 10%), rgba(0,0,0,0) calc(100% - (100% - 1440px)/2 - 5%), rgba(0,0,0,0.8) calc(100% - (100% - 1440px)/2 + 5%))
      z-index: 0
  &--lockup
    position: relative
    display: flex
    width: 900px
    max-width: 75%
    height: 100%
    align-items: center
    justify-content: flex-end
    margin: 0 auto

    @media (max-width: 1180px)
      max-width: 90%

    @media (max-width: 767px)
      justify-content: center

    .card
      padding: 45px 42px
      text-align: center
      background-color: $black
      box-shadow: 0 0 30px 0 rgba(0,0,0,.75)
      border-radius: $radius
      z-index: 10000
      &--information
        margin-bottom: 30px
        a,p
          margin: 7px 0
          text-decoration: none
          color: $white
          font-weight: 700
          margin-top: 0
          display: flex
          gap: 10px
          align-items: center
          justify-content: center

        p
          margin-top: 0
          margin-bottom: auto
          &.call
            margin: 14px 0

      &--options
        margin: 0
        padding: 0
        list-style: none

        & > li
          width: 130px
          margin: 0 auto 25px auto
          border-radius: $radius

        li:nth-child(1)
          background-image: linear-gradient(-115deg, #007bb6 0%, #05359d 95%)
          transition: opacity 0.6s
          &:hover
            opacity: 0.5

        li:nth-child(2)
          background-image: linear-gradient(-115deg, #00e6ff 0%, #08a0e9 50%, #155bf3 95%)
          transition: opacity 0.6s
          &:hover
            opacity: 0.5

        li:nth-child(3)
          margin-bottom: 0
          text-transform: uppercase
          +bg-gradient
          transition: opacity 0.6s
          &:hover
            opacity: 0.5

        a
          display: block
          width: 100%
          padding: 8px 0
          text-decoration: none
          color: $white
          font-weight: 700

.loader
  display: none
  position: fixed
  top: 40%
  left: 48%
  transform: translate(-40%, -45%)
  border: 16px solid #fff
  border-top: 16px solid #db6533
  border-radius: 50%
  width: 100px
  height: 100px
  animation: spin 2s linear infinite

@keyframes spin
  0%
    transform: rotate(0deg)
  100%
    transform: rotate(360deg)
