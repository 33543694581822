.work
  position: relative
  display: flex
  width: 960px
  max-width: 80%
  height: 100%
  flex-direction: column
  justify-content: center
  margin: 0 auto

  @media (max-width: 1180px)
    max-width: 100%

  h2
    margin: 0 0 20px 0
    font-size: 30px
    text-align: center
    letter-spacing: 12px
    font-family: "Segoe UI"
    font-weight: 600
    text-transform: uppercase

  &--lockup
    position: relative
    height: 400px
    display: flex

    .slider
      position: relative
      display: flex
      width: 80%
      margin: 0 auto
      padding: 0
      list-style: none
      transition: opacity 0.3s ease-in-out

      &--item
        position: absolute
        display: none
        text-align: center

        a
          text-decoration: none
          color: #858585
          cursor: default

        &-title
          margin-top: 10px
          font-size: 12px
          font-weight: 700
          text-transform: uppercase
          letter-spacing: 1px
          font-weight: 700
          text-align: center

        &-description
          display: none
          max-width: 400px
          margin: 0 auto
          font-weight: 500
          text-align: center

        &-image
          width: 150px
          height: 150px
          margin: 0 auto
          border-radius: 50%
          overflow: hidden
          cursor: pointer
          transition: opacity 0.5s

          &:hover
            opacity: 0.5

          &:hover ~ .slider--item-title
            text-decoration: underline

          img
            width: 100%

        &-left
          top: 50%
          left: 0
          transform: translateY(-50%)
          display: block
          pointer-events: none
          cursor: default
          opacity: 0.5

        &-right
          top: 50%
          right: 0
          transform: translateY(-50%)
          display: block
          pointer-events: none
          cursor: default
          opacity: 0.5

        &-center
          position: relative
          top: 30px
          left: 50%
          transform: translateX(-50%)
          display: block

          img
            transition: opacity 0.6s
            &:hover
              opacity: 0.5

          a
            color: $white

          .slider--item-title
            display: inline-block
            margin-top: 25px
            font-size: 18px
            color: #DA4735
            cursor: pointer

            @media (max-width: 600px)
              text-decoration: underline

            &:hover
              text-decoration: underline

          .slider--item-description
            display: block

          .slider--item-image
            width: 300px
            height: 300px

    .slider--next,
    .slider--prev
      position: absolute
      top: 160px
      display: flex
      width: 50px
      height: 50px
      align-items: center
      justify-content: center
      background-color: $muted-gray
      border-radius: 50%
      cursor: pointer
      transition: transform 0.4s

      &:hover
        transform: scale(1.2)

      svg
        width: 20px
        fill: $white

    .slider--next
      right: 0

    .slider--prev
      left: 0

.hover
  transition: opacity 0.5s
  opacity: 0.3

@media (max-width: 1024px)

  .work

    &--lockup

      .slider

        &--item

          &-image
            pointer-events: none

          &-title
            pointer-events: none


@media (max-width: 900px)

  .work

    &--lockup

      .slider

        &--item

          &-image
            width: 120px
            height: 120px

          &-center

            .slider--item-image
              width: 240px
              height: 240px

      .slider--next,
      .slider--prev
        top: 130px

@media (max-width: 768px)

  .work

    &--lockup

      .slider
        width: 75%

        &--item

          &-left,
          &-right
            top: 37%

          &-image
            width: 90px
            height: 90px

          &-center

            .slider--item-image
              width: 190px
              height: 190px

      .slider--next,
      .slider--prev
        top: 105px

@media (max-width: 600px)

  .work

    &--lockup

      .slider
        width: auto

        &--item

          &-left,
          &-right
            display: none
