::selection
  background: #8B6600

::-moz-selection
  background: #8B6600

img::selection
  background: transparent

img::-moz-selection
  background: transparent

body
  -webkit-tap-highlight-color: #8B6600
